import React, { useState, useEffect } from 'react';

import { list } from './utube'
import { WaveScrollContaner } from 'jwave-scroll';

function App() {

  const [items, setItems] = useState(new Array(10).fill({}));

  const [scrollTop, setScrollTop] = useState(0);
  const [contentPanes, setContentPanes] = useState(list);


  const loadFunc = (props) => {
    // setContentPanes(new Array(10).fill({}))
    setItems([...items, ...new Array(10).fill({})])
  }

  useEffect(() => {
    if (!items.length) {
      loadFunc()
    }

    new WaveScrollContaner({scrollFactor:.04})
  }, []);

  return (
    <div
      className="App">

      <div

        id="wave-scroll-container"

        onScroll={(event) => {

          //   if (timeout) {
          //     window.clearTimeout(timeout);
          //   }
          // const { scrollTop } = document.getElementById('scrollio')
          //setScrollTop(scrollTop)

          //   timeout = window.setTimeout(function () {
          //     const { scrollHeight, scrollTop } = document.getElementById('scrollio')
          //    // setScrollTop(scrollTop)
          //     const diff = scrollHeight - (scrollTop + window.innerHeight)
          //     if (diff < (window.innerHeight * .2)) {
          //      // loadFunc()
          //     }
          //   }, 100);
        }}
        className="scroll-y h-100 d-flex flex-wrap justify-content-center"
      >


        {/* {
          items.map((item, i) => {
            return (
              <img
                style={{
                  zIndex: 1,
                  top: 0,
                  opacity: Math.random(),
                  left: 0,
                  transform:'opacity .5 ease',
                  position: 'relative',
                  width: '100%',
                  maxWidth: 600,
                  height: 400,
                }}
                src="/ui/img/datacloud.png"
                alt=""
              />
            )
          })
        } */}

        <div
          style={{
            position: 'relative',
            top: 0,
            left: 0,
          }}
          id="wave-content"

          className="wave-content h-100 w-100" >

          {
            contentPanes.map((contentPane, i) => {

              return (
                <div
                  style={{
                    position: 'relative',
                  }}
                  key={`c-pain-${i}`}

                  id={`c-pain-${i}`}
                  className="wavelet-pane h-100 w-100 pl-3">
                  <br />
                  <br />
                  <p
                    className='wavelet wavelet-tx-.2'
                    style={{
                      width: 'fit-content',
                      position: 'relative',
                      fontSize: '24px',
                      color: 'rgb(25, 146, 221)',
                      backgroundColor: 'black'
                    }}
                  >
                    {contentPane.title.split('&quot;').join('"')}
                  </p>
                  <span
                    style={{
                      position: 'relative',
                      fontSize: '10vw',
                      fontSize: '4vw',
                      zIndex: 10,
                      color: 'green'
                    }}
                  >
                    {`${i} ${contentPane.title.split('&quot;').join('')}`}
                  </span>
                  <br />
                  <a target="_blank" href={contentPane.link}>
                    <img
                      className='wavelet wavelet-ty-.3'

                      src={contentPane.img}
                      style={{
                        zIndex: 1,
                        position: 'relative',
                        height: 300,
                      }}
                    />
                  </a>
                  <p
                    className='wavelet wavelet-ty-.4'

                    style={{
                      position: 'absolute',
                      color: 'white',
                      fontWeight: 800,
                      top: '50%',
                      left: '50%',
                      zIndex: 10,
                      fontSize: '4vw',
                    }}
                  >
                    {contentPane.title.split('&quot;').join('"')}
                  </p>
                </div>
              )
            })
          }
        </div>



      </div>
    </div>
  );
}

export default App;
