

const list = [
   {
      "title": "Wiz Khalifa - &quot;Paperbond&quot;",
      "img": "https://i.ytimg.com/vi/jOMXMmKg74Y/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=jOMXMmKg74Y",
      "channel": "https://www.youtube.com/channel/UCVp3nfGRxmMadNDuVbJSk8A"
   },
   {
      "title": "OFFICIAL MUSIC VIDEO: Snoop Dogg f. Wiz Khalifa - That Good",
      "img": "https://i.ytimg.com/vi/WJq2drq17Q8/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=WJq2drq17Q8",
      "channel": "https://www.youtube.com/channel/UC-OO324clObi3H-U0bP77dw"
   },
   {
      "title": "Wiz Khalifa - Pull Up ft. Lil Uzi Vert [Official Video]",
      "img": "https://i.ytimg.com/vi/QIFnS4X90x4/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=QIFnS4X90x4",
      "channel": "https://www.youtube.com/channel/UCVp3nfGRxmMadNDuVbJSk8A"
   },
   {
      "title": "Wiz Khalifa x Big Sean x Curren$y - Proceed (Official Video)",
      "img": "https://i.ytimg.com/vi/wb-SloIiEoE/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=wb-SloIiEoE",
      "user": "https://www.youtube.com/user/Loza6999"
   },
   {
      "title": "DRAM - Broccoli feat. Lil Yachty (Official Music Video)",
      "img": "https://i.ytimg.com/vi/K44j-sb1SRY/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=K44j-sb1SRY",
      "channel": "https://www.youtube.com/channel/UC89dItegSy120X9dAhnKxMQ"
   },
   {
      "title": "Young Thug, Travis Scott - Pick Up the Phone (Explicit) (Official Music Video) ft. Quavo",
      "img": "https://i.ytimg.com/vi/mZDinQ92OZQ/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=mZDinQ92OZQ",
      "channel": "https://www.youtube.com/channel/UCtxdfwb9wfkoGocVUAJ-Bmg"
   },
   {
      "title": "Wiz Khalifa x Curren$y - You In Mind (Official Video)",
      "img": "https://i.ytimg.com/vi/xQ8Jo-lsN3Y/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=xQ8Jo-lsN3Y",
      "user": "https://www.youtube.com/user/Loza6999"
   },
   {
      "title": "Keith Ape - Let Us Prey (Official Music Video) feat Bryan Cha$e",
      "img": "https://i.ytimg.com/vi/wwtG_DVqMa4/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=wwtG_DVqMa4",
      "channel": "https://www.youtube.com/channel/UCZW5lIUz93q_aZIkJPAC0IQ"
   },
   {
      "title": "Snakehips - Gone (feat. Syd)",
      "img": "https://i.ytimg.com/vi/FmXz9V4vJmA/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=FmXz9V4vJmA",
      "user": "https://www.youtube.com/user/majesticcasual"
   },
   {
      "title": "Bob Marley ~ One Love",
      "img": "https://i.ytimg.com/vi/eu9fF4_rnPo/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=eu9fF4_rnPo",
      "user": "https://www.youtube.com/user/StupidYanksUp911"
   },
   {
      "title": "BOB MARLEY - JAMMING (Live)",
      "img": "https://i.ytimg.com/vi/5WlCdiU9IzA/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=5WlCdiU9IzA",
      "user": "https://www.youtube.com/user/islandrecords"
   },
   {
      "title": "Bob Marley &amp; The Wailers -  No Woman No Cry (Live)",
      "img": "https://i.ytimg.com/vi/SV2hshsChlk/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=SV2hshsChlk",
      "user": "https://www.youtube.com/user/samamithas"
   },
   {
      "title": "Damian &quot;Jr. Gong&quot; Marley - Welcome To Jamrock (Official Video)",
      "img": "https://i.ytimg.com/vi/_GZlJGERbvE/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=_GZlJGERbvE",
      "channel": "https://www.youtube.com/channel/UCV0TnBGv6txr79Oiwtmsm-g"
   },
   {
      "title": "Love Is My Religion - Ziggy Marley live performance | GRAMMYs",
      "img": "https://i.ytimg.com/vi/K5Wk-7-bLRM/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=K5Wk-7-bLRM",
      "user": "https://www.youtube.com/user/TheGRAMMYs"
   },
   {
      "title": "Bone Thugs N Harmony - Weed Song",
      "img": "https://i.ytimg.com/vi/bHN1NKZjckc/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=bHN1NKZjckc",
      "user": "https://www.youtube.com/user/THELimeRicky"
   },
   {
      "title": "Bone Thugs-N-Harmony --- Buddah Lovaz",
      "img": "https://i.ytimg.com/vi/yFmCVQWRReM/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=yFmCVQWRReM",
      "user": "https://www.youtube.com/user/5hannyn"
   },
   {
      "title": "Bizzy Bone - Fried Day",
      "img": "https://i.ytimg.com/vi/BlpjIPF3xMM/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=BlpjIPF3xMM",
      "user": "https://www.youtube.com/user/THELimeRicky"
   },
   {
      "title": "Bizzy Bone - Weed Man",
      "img": "https://i.ytimg.com/vi/fBOgN4Lm3oE/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=fBOgN4Lm3oE",
      "user": "https://www.youtube.com/user/HalTat723"
   },
   {
      "title": "T-Pain: Need To Be Smokin | NPR MUSIC FRONT ROW",
      "img": "https://i.ytimg.com/vi/plm1JUJhz4I/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=plm1JUJhz4I",
      "user": "https://www.youtube.com/user/nprmusic"
   },
   {
      "title": "Bone Thugs N Harmony - Budsmokers Only",
      "img": "https://i.ytimg.com/vi/gf_9oyfl-gA/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=gf_9oyfl-gA",
      "user": "https://www.youtube.com/user/Kings10Bibby"
   },
   {
      "title": "Bone Thugs N Harmony - Blaze It (extended)",
      "img": "https://i.ytimg.com/vi/18ow1YroiEg/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=18ow1YroiEg",
      "user": "https://www.youtube.com/user/Thys93"
   },
   {
      "title": "Hyyerr (Kid Cudi x Chip Tha Ripper)",
      "img": "https://i.ytimg.com/vi/iYn2_lDzfR0/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=iYn2_lDzfR0",
      "user": "https://www.youtube.com/user/goodmuzique"
   },
   {
      "title": "Mila J - Kickin’ Back (Official Video)",
      "img": "https://i.ytimg.com/vi/T11NVNwBJ38/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=T11NVNwBJ38",
      "channel": "https://www.youtube.com/channel/UCAvZORXwc7MYjCSRW1F-IDw"
   },
   {
      "title": "Bone Thugs-N-Harmony P.O.D",
      "img": "https://i.ytimg.com/vi/U5lM2Y8WtnE/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=U5lM2Y8WtnE",
      "user": "https://www.youtube.com/user/blade401k"
   },
   {
      "title": "P.O.D - B.O.N.E. T.H.U.G.S. -N- HARMONY",
      "img": "https://i.ytimg.com/vi/lnS2maLJUDQ/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=lnS2maLJUDQ",
      "user": "https://www.youtube.com/user/gambinobone"
   },
   {
      "title": "Wiz Khalifa x Curren$y - Weed Nap (Official Video)",
      "img": "https://i.ytimg.com/vi/cQaonSjsJmk/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=cQaonSjsJmk",
      "user": "https://www.youtube.com/user/Loza6999"
   },
   {
      "title": "Stephen Marley - The Traffic Jam ft. Damian Marley",
      "img": "https://i.ytimg.com/vi/iqIn9N_fJJY/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=iqIn9N_fJJY",
      "channel": "https://www.youtube.com/channel/UC3TjpVgIwQQ_YCViABi-Gag"
   },
   {
      "title": "Sublime - Smoke Two Joints",
      "img": "https://i.ytimg.com/vi/h0i62GnQoo0/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=h0i62GnQoo0",
      "user": "https://www.youtube.com/user/chiefthriller"
   },
   {
      "title": "I Smoke Two Joints",
      "img": "https://i.ytimg.com/vi/vUsv4Lb7EQs/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=vUsv4Lb7EQs",
      "user": "https://www.youtube.com/user/Artur19301930"
   },
   {
      "title": "Stevie Wonder - All i Do",
      "img": "https://i.ytimg.com/vi/BMxPsZuouY8/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=BMxPsZuouY8",
      "user": "https://www.youtube.com/user/balizalai"
   },
   {
      "title": "Matt Martians - &quot;Dent Jusay&#39; ft. Syd &amp; Steve Lacy",
      "img": "https://i.ytimg.com/vi/RDDLGfghuMA/hqdefault.jpg",
      "link": "https://www.youtube.com/watch?v=RDDLGfghuMA",
      "user": "https://www.youtube.com/user/Jetageoftomorrow"
   }
]

export { list }